<template>
  <div class="services__container"> 
    <div class="services__content">      
      <div class="services__body">
        <div class="services__text uppercase">Services we offer:</div>
        <div class="services__items">
          <div class="services__item">
            <img src="./../assets/service_and_repairs.webp" >
            <div class="services__item_header">
              Service &amp; repair
            </div>
            <div class="services__item_body">
              We offer quality mechanical and electrical servicing and repairs to many makes and models of metal and plastic cutting CNC and conventional machine tools and we 
              specialise in the reconditioning of machine tool spindles while maintaining spindle rigidity and accuracy.
            </div>
          </div>
          <div class="services__item">
            <img src="./../assets/spares.webp" >
            <div class="services__item_header">
              Source spares
            </div>
            <div class="services__item_body">
              We source machine spares on behalf of our customers, saving time and effort and we specialise in sourcing and matching of high precision spindle bearings to the 
              spindle shaft.
            </div>
          </div>          
          <div class="services__item">
            <img src="./../assets/commission.webp" >
            <div class="services__item_header">
              Install &amp; commission
            </div>
            <div class="services__item_body">
            We install and commission machine tools to their manufacturers specifications, maintaining the machine tool builders specifications and precision. We also precision 
            level machines and adjust the machine geometry so as to achieve the optimal squareness and accuracy of the machine tool.
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Services',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .services__container {
    display: flex;
    flex-grow: 1;
    background-color: var(--background-color);
    padding: 2rem 0;
  }

  .services__content {
    color:var(--grey);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .services__body {
    display: flex;
    flex-direction: column;
    justify-content: center;    
    max-width: 1000px;
  }

  .services__text {
    display: flex;
    justify-content: center;

    width: 100%;
    font-size: 2.125rem;
    font-weight: bold;
  }

  .services__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5rem;
    padding: 2rem;
  }

  .services__item {
    display: flex;
    flex-direction: column;

    > img {
      width: 100%;
    }

    .services__item_header {
      padding-top: 1rem;
      text-transform: uppercase;
      font-size: 2rem;
    }
    
    .services__item_body {
      padding-top: 1rem;
      font-size: 1.25rem;
    }
  }

  @media only screen and (min-width: 1500px) {  
    .services__container {
      padding: 2rem 10rem;
    }

    .services__items {
      column-gap: 5rem;
    } 
  }

  @media only screen and (max-width: 800px) {
    .services__items {
      column-gap: 1rem;
    }    
  }

  @media only screen and (max-width: 650px) {
    .services__items {  
      grid-template-columns: 1fr 1fr;
      row-gap: 2rem;
      padding: 2rem;
    }    
  }
  
  @media only screen and (max-width: 500px) {
    .services__items {  
      grid-template-columns: 1fr;
      row-gap: 5rem;
    }    
  }
</style>
