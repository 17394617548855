<template>
  <div class="header"  v-bind:class="{ blue: this.$route.path === Pages.Home, red: this.$route.path === Pages.Services, green: this.$route.path === Pages.Gallery, black: this.$route.path === Pages.ContactUs }">
    <div class="logo">
      <img alt="Exroth logo" src="./assets/exroth_logo.png" >
    </div>
    <div class="navigation uppercase bold">
      <div class="navigation-item blue" v-bind:class="{ selected: this.$route.path === Pages.Home }" ><router-link :to="Pages.Home">Home</router-link></div>
      <div class="navigation-item red" v-bind:class="{ selected: this.$route.path === Pages.Services }" ><router-link :to="Pages.Services">Services</router-link></div>
      <div class="navigation-item green" v-bind:class="{ selected: this.$route.path === Pages.Gallery }" ><router-link :to="Pages.Gallery">Gallery</router-link></div>
      <div class="navigation-item black" v-bind:class="{ selected: this.$route.path === Pages.ContactUs }" ><router-link :to="Pages.ContactUs">Contact Us</router-link></div>
    </div>
  </div>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  meta: {

  },
  data () {
    return {
      selectedPage: this.$route.path,
      Pages
    };
  },
}

const Pages = {
  Home: '/',
  Services: '/services',
  Gallery: '/gallery',
  ContactUs: '/contact-us'
}

</script>

<style lang="scss">
  @font-face {
    font-family: Gisha;
    src: url("./fonts/GISHA.TTF") format("truetype");
  }

  :root {
    --blue: #0303fd;
    --red: #c80803;
    --green: #15820d;
    --black: #000100;

    --grey: #404040;
    --background-color: #dfdfdf;
  }

  body {
    margin: 0;
    height: 100vh;
  }

  #app {
    font-family: Gisha, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    height:100%;
    
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }

  .header {
    scrollbar-gutter: stable;
    width: 100vw;
    display: flex;
    flex-direction: column;

    background-image: url("./assets/blueprint_banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    border-bottom-style: solid;
    border-bottom-width: 0.5rem;
    &.blue {
      border-bottom-color: var(--blue);
    }
    &.red {
      border-bottom-color: var(--red);
    }
    &.green {
      border-bottom-color: var(--green)
    }     
  }

  .logo {
    justify-content: left;
    padding: 1rem 0 0 10rem;
    width: 40%;

    > img { 
      width: 100%;
    }
  }

  .navigation {
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10rem;

    .navigation-item {
      color: var(--grey);
      font-size: 24px;
      cursor: pointer;      
      padding: 0.25rem 0.75rem;

      a {
        text-decoration: unset !important;
        color: unset !important;
      }
    }
    
    .selected {
      color: #f2f2f2;
      &.blue {
        background-color: var(--blue);
      }
      &.red {
        background-color: var(--red);
      }
      &.green {
        background-color: var(--green);
      }
      &.black {
        background-color: var(--black);
      }
    }
  }

  .content {
    height: 100%;
  }

  .uppercase {    
      text-transform: uppercase;
  }

  .bold {
    font-weight: bold;
  }

  .italics {
    font-style: italic;
  }

  .pointer {
    cursor: pointer;
  }

  .underline {
    text-decoration: underline;  
  }

  @media only screen and (max-width: 815px) {    
    .navigation {
      .navigation-item {
        font-size: 20px;
      }
    }
  }

  @media only screen and (max-width: 800px) {

    .logo {
      justify-content: left;
      padding: 1rem 2rem 0 2rem;
      width: 90%;

      > img { 
        width: 100%;
      }
    }
    
    .navigation {
      height: 3rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 2rem;

    }
  }

  @media only screen and (max-width: 600px) {
    .header {
      scrollbar-gutter: stable;
      width: 100vw;
      display: flex;
      flex-direction: column;

      background-image: url("./assets/blueprint_banner.jpg");
      background-repeat: no-repeat;
      background-size: cover;

      border-bottom-style: solid;
      border-bottom-width: 0.25rem;
    }

    .logo {
      justify-content: left;
      padding: 1rem 2rem 0 2rem;
      width: 90%;

      > img { 
        width: 100%;
      }
    }
  }
</style>
