import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'

import Home from './components/Home.vue';
import Services from './components/Services.vue';
import ContactUs from './components/ContactUs.vue';
import Gallery from './components/Gallery.vue';


const routes = [
  { path: '/', component: Home },
  { path: '/services', component: Services },
  { path: '/gallery', component: Gallery },
  { path: '/contact-us', component: ContactUs },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

createApp(App).use(router).mount('#app')
