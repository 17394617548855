<template>
  <div class="contact_us__container">
    <div class="contact_us__title uppercase bold">
      Get in touch
    </div>
    <div class="contact_us__content">
      <div class="contact_us__details">
        <div class="contact_us__items">
          <div class="contact_us__contact_details">
            <div class="bold">Email:</div>
              <div 
                ref="invisibleHcaptcha"
                v-bind:class="{pointer: !viewed, underline: !viewed}" 
                v-on:click="view_clicked()"
              > 
                {{ email_address }}
              </div>
            <div class="bold">Phone:</div>
            <div
              v-bind:class="{pointer: !viewed, underline: !viewed}"
              v-on:click="view_clicked()"
            > 
              {{ phone_number }}
            </div>
          </div>
          <div class="contact_us__address">
            <div class="bold">Postal Address:</div> 
            <div>
              PO Box 2704 <br/>
              Rooihuiskraal <br/>
              0154 <br/>
              South Africa <br/>
            </div>
          </div>
        </div>
      </div>  
      <div class="contact_us__map">
        <img src="../assets/map.png" />
        <p>
          Based in Centurion City, we are centrally located to service greater Johannesburg and Pretoria and will travel to consult in other major cities in South Africa.
        </p>
      </div>
    </div>
    <div class="contact_us__footer">
      This site is protected by <a href="https://www.hCaptcha.com">hCaptcha</a> and its
      <a href="https://www.hcaptcha.com/privacy">Privacy Policy</a> and
      <a href="https://www.hcaptcha.com/terms">Terms of Service</a> apply.
    </div>
  </div>
</template>

<script>

export default {  
  name: 'ContactUs',
  data () {
    return {
      is_viewed: false,
      email_address: 'view',
      phone_number: 'view',
      hcaptcha: null,
      hcaptcha_widget_id: null
    }
  },
  mounted() {
    this.hcaptcha = window.hcaptcha;
    const opt = {
      sitekey: '10d26b0f-2de1-4447-a0d1-2f7d8c599a20',
      size: 'invisible',
      'callback': this.successful_captcha
    };
    this.widgetId = this.hcaptcha.render(this.$el, opt);
  },
  computed: {
    viewed: {
      get() {
        return this.is_viewed;
      },
      set(value) {
        this.is_viewed = value;
      }
    }
  },
  methods: {
    view_clicked() {
      this.hcaptcha.execute(this.widgetId);
    },

    successful_captcha() {
      this.viewed = true;
      this.show_email();
      this.show_phone_number();
    },

    show_email() {
      this.is_email_viewed = true;
      const a = "com";
      const b = "contact-us";
      const c = "@";
      const d = "exrothmachinetools";
      const email_builder = b + c + d + '.' + a;

      this.email_address = email_builder;
    },

    show_phone_number() {
      const a = "82";
      const b = "71";
      const c = "45";
      const d = "27";
      const e = "9"
      const f = "17";
      const g = "+"
      const number_builder = g + d + ' ' + a + ' ' + c  + e + ' ' + f + b;

      this.phone_number = number_builder;
    },

    captcha_error() {
      alert('error')
    }
  }
}

</script>

<!-- Add 'scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .contact_us__container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: var(--background-color);
    color: var(--grey);
    padding: 2rem 0 0 0;
  }

  .contact_us__footer {
    align-self: center;
  }

  .contact_us__content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    display: grid;    
    grid-template-columns: 1fr 1fr;
    padding: 0 10rem;
  }

  .contact_us__map {
    img {
      height: 350px;
    }

    p {
      font-size: 1.25rem;
    }
  }

  .contact_us__title {
    font-size: 2.125rem;
    text-align: center;
    padding-bottom: 2rem;
  }

  .contact_us__items {
    display: flex;
    flex-direction: column;
    
    font-size: 1.25rem;
  }

  .contact_us__contact_details {
    display: grid;
    grid-template-columns: fit-content(100%) auto;
    column-gap: 1rem;
    padding-top: 2rem;
  }

  .contact_us__address {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: fit-content(100%) auto;
    padding-top: 2rem;
  }

 
 @media only screen and (max-width: 1200px) {
    .contact_us__content {
      padding: 0 2rem;
    }
 }

  @media only screen and (max-width: 800px) {
    .contact_us__content {
      grid-template-columns: 1fr;
      justify-items: center;
      padding: 0 2rem;

      img {
        height: 350px;
      }
    }

    .contact_us__title {
      padding-bottom: 0;
    }

    .contact_us__details {
      width: 100%;
      justify-items: center;
      display: inline-grid;
    }

    .contact_us__map {
      margin-top: 2rem;
      text-align: center;
    }
  }
</style>
