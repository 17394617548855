<template>
  <div class="gallery__container"> 
    <div class="gallery__content">      
      <img src="./../assets/gallery_1.webp" />
      <img src="./../assets/gallery_2.webp" />
      <img src="./../assets/gallery_3.webp" />
      <img src="./../assets/gallery_4.webp" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Gallery',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .gallery__container {
    display: flex;
    flex-grow: 1;
    background-color: var(--background-color);
    padding: 2rem 0;
  }

  .gallery__content {    
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 5rem;
    padding: 2rem;

    > img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1400px) {     

    .gallery__content {    
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 2rem;
      row-gap: 2rem;
    }
  }

  @media only screen and (max-width: 1024px) {     

    .gallery__content {    
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
      row-gap: 2rem;
    }
  }

  
  @media only screen and (max-width: 500px) {     

    .gallery__content {
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }
  }
</style>
