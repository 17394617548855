<template>
  <div class="home__container">
    <div class="home__content">
      <div class="home__banner">
        <div></div>
        <div class="home__banner_text">
          <div>Offering our clients</div>
          <div class="uppercase large">electrical &amp; mechanical </div>
          <div>
            servicing and repaires to machine tools in the metal industry
            <em>since 1997</em>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style scoped lang="scss">
  .home__container {
    height:100%;
    background-color: var(--background-color);
  }

  .home__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .home__banner {    
    background-image: url("./../assets/what_we_do_banner.webp");
    background-size: cover;

    display: grid;
    grid-template-columns: 40% 60%;
    height: 100%;
  }

  .home__banner_text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: end;

    color: #f2f2f2;
    font-size: 2rem;
    padding: 3rem 3rem 3rem 0;

    .large {
      font-size: 3.75rem;
      font-stretch: extra-expanded;
    }
  }

  @media only screen and (max-width: 1024px) {
    .home__banner {    
      background-image: 
        linear-gradient(to right, rgba(0, 0, 0, 0.275), rgba(0, 0, 0, 0.501)),
        url("./../assets/what_we_do_banner.webp");
      background-size: cover;
    }
  }

  @media only screen and (max-width: 600px) {
    .home__banner {    
      background-image: 
        linear-gradient(to right, rgba(0, 0, 0, 0.483), rgba(0, 0, 0, 0.884)),
        url("./../assets/what_we_do_banner.webp");
      background-size: cover;

      display: grid;
      grid-template-columns: 0% 100%;
      height: 100%;
    }
  }
  
  @media only screen and (max-width: 450px) {
    .home__banner_text{
      font-size: 1.5rem;
      padding: 3rem 1rem 3rem 0;
    }
  }    

</style>
